import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../heplers/translationHelper";
import TableComponent from "../../../components/table";
import { Helmet } from "react-helmet";
import { Get, Post } from "../../../actions";
import {
	Get_AllIndexationProposalsWithPagination_URL,
	Get_AllRecoveryProceduresWithPagination_URL,
	Post_ConfirmIndexationProposals_URL,
	Post_ConfirmRecoveryProcedures_URL,
	Post_DeleteRecoveryProcedure_URL,
	Post_StartRecoveryProcedure_URL,
	Post_UpdateRecoveryProcedure_URL,
	Get_AllDuesWithPagination_URL,
	Post_DueConfirmSelectedDues_URL,
	Post_DeleteDue_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { format } from "../../../heplers/format";
import { Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import AlertDialog from "../../../components/DeleteDialog";
import { Field, Formik } from "formik";
import { EditButton } from "../../../components/ButttonsWithIcons";
import RecoveryProcedureStepper from "../../tanents/components/recoveryprocedure/components/recoveryProcedureStepper";
import DialogComponent from "../../../components/Dialog";
import { string } from "yup";
const DashBoardDataTable = ({
	dataType,
	indexationList,
	indexationListCount,
	setIndexationListCount,
	setIndexationList,
	setRecoveryList,
	recoveryList,
	dueList,
	setDueList,
}) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [searchString, setSearchString] = useState("");
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [selectRow, setSelectRow] = useState([]);
	const [refResh, setRefResh] = useState(false);

	const [page, setPage] = useState(0);
	let pageSize = localStorage.getItem("sizeIndexation");
	if (pageSize == null) {
		localStorage.setItem("sizeIndexation", 10);
		pageSize = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(pageSize);
	const [selected, setSelected] = useState({});
	const handlePercentageChange = (row, percentage, newRent) => {
		const tempArray = indexationList;

		var index = tempArray.findIndex((x) => x.id == row.id);
		var item = tempArray[index];
		tempArray[index] = {
			...item,
			percentage: percentage,
			newRent: format("number", newRent),
		};
		setIndexationList([...tempArray]);
	};

	const columns = [
		{
			id: "tenantName",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Tenant Name", "Tenant Name", "Tenant Name"),
		},
		{
			id: "startDate",
			disablePadding: true,
			label: getTranslation("Contract Date", "Contract Date", "Contract Date"),
			component: ({ row }) => {
				return (
					<Typography style={{ color: "red" }}>
						{format("date", row?.startDate)}
					</Typography>
				);
			},
		},
		{
			id: "oldIndex",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Old Index", "Old Index", "Old Index"),
			component: (row) => {
				return (
					<Typography variant="h5" style={{ textAlign: "right" }}>
						{format("number", row?.row?.oldIndex)}
					</Typography>
				);
			},
		},
		{
			id: "oldRent",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Rent", "Rent", "Rent"),
			component: (row) => {
				return (
					<Typography variant="h5" style={{ textAlign: "right" }}>
						{format("number", row?.row?.oldRent)}
					</Typography>
				);
			},
		},
		{
			id: "newIndex",
			numeric: false,
			disablePadding: true,
			label: getTranslation("New Index", "New Index", "New Index"),
			component: (row) => {
				return (
					<Typography variant="h5" style={{ textAlign: "right" }}>
						{format("number", row?.row?.newIndex)}
					</Typography>
				);
			},
		},
		{
			id: "newProposal",
			numeric: false,
			disablePadding: true,
			label: getTranslation("New Proposal", "New Proposal", "New Proposal"),
			component: (row) => {
				return (
					<Typography variant="h5" style={{ textAlign: "right" }}>
						{format("number", row?.row?.newProposal)}
					</Typography>
				);
			},
		},
		{
			id: "percentage",
			numeric: false,
			disablePadding: true,
			label: getTranslation(
				"Percentage (%)",
				"Percentage (%)",
				"Percentage (%)"
			),
			component: ({ row }) => {
				return (
					<MyFied
						row={row}
						handlePercentageChange={handlePercentageChange}
						selected={selected}
					/>
				);
			},
		},
	];

	const recoveryColumns = [
		{
			id: "tenant",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Tenant Name", "Tenant Name", "Tenant Name"),
			component: ({ row }) => {
				return <Typography>{row?.tenant?.name}</Typography>;
			},
		},
		{
			id: "apartmentAddress",
			label: getTranslation(
				"Appartment Address",
				"Appartment Address",
				"Appartment Address"
			),
			align: "left",
			component: ({ row }) => {
				return <Typography>{row?.appartmentAddress}</Typography>;
			},
		},
		{
			id: "recoverystatus",
			label: getTranslation("Status", "Status", "Status"),
			align: "left",
			component: ({ row }) => {
				return (
					<Typography>
						{row?.recoveryProcedureStatus == 0
							? "Active"
							: row?.recoveryProcedureStatus == 1
							? "Closed"
							: "Draft"}
					</Typography>
				);
			},
		},
		{
			id: "recoveryPersons",
			disablePadding: true,
			label: getTranslation(
				"Recovery Person",
				"Recovery Person",
				"Recovery Person"
			),
			component: ({ row }) => {
				return (
					<>
						<Typography>
							{row?.recoveryPersons[0]?.name} | {row?.recoveryPersons[0]?.email}
						</Typography>
						<Typography>
							{row?.recoveryPersons[1]
								? row?.recoveryPersons[1]?.name + " | "
								: ""}{" "}
							{row?.recoveryPersons[1] ? row?.recoveryPersons[1]?.email : ""}
						</Typography>
						<Typography>
							{row?.recoveryPersons[2]
								? row?.recoveryPersons[2]?.name + " | "
								: ""}{" "}
							{row?.recoveryPersons[2] ? row?.recoveryPersons[2]?.email : ""}
						</Typography>
					</>
				);
			},
		},
		{
			id: "actions",
			label: getTranslation("Action", "Action", "Action"),
			align: "right",
		},
	];

	const duesColumns = [
		{
			id: "date",
			disablePadding: true,
			label: getTranslation("Date", "Date", "Date"),
			format: "date",
		},
		{
			id: "name",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Name", "Name", "Name"),
		},
		{
			id: "categoryText",
			numeric: true,
			label: getTranslation("Activity", "Activity", "Activity"),
			component: ({ row }) => (
				<Typography>
					{row?.categoryText
						? row.categoryText
								.replace(
									"Rent- Due",
									getTranslation("Rent-Due", "Rent-Due", "Rent-Due")
								)
								.replace(
									"Warranty- Payment",
									getTranslation(
										"Warranty-Payment",
										"Warranty-Payment",
										"Warranty-Payment"
									)
								)
								.replace(
									"Rent- Payment",
									getTranslation("Rent-Payment", "Rent-Payment", "Rent-Payment")
								)
								.replace(
									"Repair & Maintenance- Payment",
									getTranslation(
										"Repair & Maintenance-Payment",
										"Repair & Maintenance-Payment",
										"Repair & Maintenance-Payment"
									)
								)
								.replace(
									"Repair & Maintenance- Due",
									getTranslation(
										"Repair & Maintenance-Due",
										"Repair & Maintenance-Due",
										"Repair & Maintenance-Due"
									)
								)
								.replace(
									"Cost- Due",
									getTranslation("Cost-Due", "Cost-Due", "Cost-Due")
								)
						: "-"}
				</Typography>
			),
		},
		{
			id: "buildingName",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Building", "Building", "Building"),
		},
		{
			id: "appartmentNo",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Apartments", "Apartments", "Apartments"),
		},
		{
			id: "paymentAmount",
			format: "number",
			align: "right",
			label: getTranslation("Payment", "Payment", "Payment"),
			component: (row) => {
				return (
					<Typography>{format("number", row?.row?.paymentAmount)}</Typography>
				);
			},
		},
		{
			id: "dueAmount",
			format: "number",
			align: "right",
			label: getTranslation("Due", "Due", "Due"),
			component: (row) => {
				return <Typography>{format("number", row?.row?.dueAmount)}</Typography>;
			},
		},
		{
			id: "comments",
			label: getTranslation("Comments", "Comments", "Comments"),
			component: ({ row }) => {
				return (
					<>
						{" "}
						{row.comments?.split(/\r?\n/).map((item) => {
							return <Typography>{item}</Typography>;
						})}
					</>
				);
			},
		},
		{
			id: "actions",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Actions", "Actions", "Actions"),
			align: "right",
		},
	];

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeIndexation", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeIndexation"));
		setPage(0);
	};
	const handleSearchappartment = (searchString) => {
		setSearchString(searchString);
	};

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: {
				title: "Indexation",
				url: "/dues/indexation",
			},
		});
	}, []);

	useEffect(() => {
		getIndexations();
		getRecoveryProcedure();
		loadDues();
		setIsLoading(true);
		setSelectRow([]);
		setRefResh(false);
	}, [searchString, dataType, refResh, page, rowsPerPage]);

	//get all the contract that need to be indexed
	const getIndexations = () => {
		Get(
			{
				pageNumber: page,
				pageSize: rowsPerPage,
				searchString: searchString,
			},
			Get_AllIndexationProposalsWithPagination_URL,
			null,
			(resp) => {
				setIndexationList(resp?.data.data);
				setIndexationListCount(resp.data);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	//getting all recovery procedure
	const getRecoveryProcedure = () => {
		Get(
			{
				sort: "startDate",
				sortDirection: 1,
				pageNumber: page,
				pageSize: rowsPerPage,
				status: "draft",
				searchString: searchString,
			},
			Get_AllRecoveryProceduresWithPagination_URL,
			null,
			(resp) => {
				setRecoveryList(resp?.data);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	//geting all dues
	const loadDues = (values) => {
		Get(
			{
				pageNumber: page,
				pageSize: rowsPerPage,
				searchString: searchString,
			},
			Get_AllDuesWithPagination_URL,
			null,
			(resp) => {
				// setDuesLoadign(false);
				setDueList(resp?.data);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	//post indexation confirm list
	const postIndexation = () => {
		if (selectRow.length == 0) {
			enqueueSnackbar(
				getTranslation(
					"No tenant selected to confirm indexation",
					"No tenant selected to confirm indexation",
					"No tenant selected to confirm indexation"
				),
				{
					variant: "error",
				}
			);
			return;
		}

		let payload = selectRow.map((row) => {
			if (typeof row.newRent === "string") {
				row.newRent = parseFloat(row.newRent);
			}
			return row;
		});

		Post(
			{
				// confirmedProposals: selectRow,
				// confirmedProposals: {...selectRow, parseFloat(selectRow.newRent)}
				confirmedProposals: payload,
			},
			Post_ConfirmIndexationProposals_URL,
			null,
			(resp) => {
				enqueueSnackbar("Indexation is successfully confirmed", {
					variant: "success",
				});
				setRefResh(true);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
			}
		);
			}
		);
	};

	const confirmRecoveryProcedures = (payload) => {
		if (payload.length == 0) {
			enqueueSnackbar(
				getTranslation(
					"No tenant selected to start Recovery Procedure",
					"No tenant selected to start Recovery Procedure",
					"No tenant selected to start Recovery Procedure"
				),
				{
				variant: "error",
				}
			);
			return;
		}

		var selectedRecoveryProcedures = payload.map((x) => {
			return x.id;
		});
		setIsLoading(true);
		Post(
			selectedRecoveryProcedures,
			Post_ConfirmRecoveryProcedures_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Recovery Procedures started successfully",
						"Recovery Procedures started successfully",
						"Recovery Procedures started successfully"
					),
					{
						variant: "success",
					}
				);
				setRefResh(true);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	//open confirm dialog
	const confirmDialog = () => {
		// dataType == 0 && indexationList?.length == 0
		// ? setIsOpenDialog(false)
		// : dataType == 1 && recoveryList?.length == 0
		// ? setIsOpenDialog(false)
		// : setIsOpenDialog(true);
		setIsOpenDialog(true);
	};

	const confirmDues = (payload) => {
		if (payload.length == 0) {
			enqueueSnackbar(
				getTranslation(
					"No due selected to confirm",
					"No due selected to confirm",
					"No due selected to confirm"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		Post(
			{
				confirmDues: payload,
			},
			Post_DueConfirmSelectedDues_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Dues confirmed successfully",
						"Dues confirmed successfully",
						"Dues confirmed successfully"
					),
					{
						variant: "success",
					}
				);
				setRefResh(true);
			},
			(err) => {
				enqueueSnackbar(getTranslation(err?.data, err?.data, err?.data), {
					variant: "error",
				});
			}
		);
	};

	//call the postindexation api
	const handleConfirmIndexation = () => {
		if (dataType === "0") {
			postIndexation();
		} else if (dataType === "1") {
			confirmRecoveryProcedures(selectRow);
		} else if (dataType === "2") {
			confirmDues(selectRow);
		}

		setIsOpenDialog(false);
	};
	const handleSelectAll = (row) => {
		if (row.length == selectRow.length) {
			setSelectRow([]);
		} else setSelectRow(row);
	};
	const handleSelectionUnChecked = (row) => {
		let updateArray = selectRow.filter((x) => x.id != row);
		setSelectRow(updateArray);
	};
	const handleSelectionChecked = (row) => {
		if (selectRow) setSelectRow([...selectRow, row]);
		else setSelectRow([row]);
	};
	const [editRecoveryData, setEditRecoveryData] = useState();
	const [startRecoveryDialog, setStartRecoveryDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [deletedRow, setDeletedRow] = useState();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const onEditRecovery = (recoveryData) => {
		setEditRecoveryData(recoveryData);
		setIsEdit(true);
		setStartRecoveryDialog(true);
	};

	const handleOpenDeleteDialog = (row) => {
		setDeletedRow(row);
		setOpenDeleteDialog(true);
	};

	const handleDeleteDue = async () => {
		const deleteArray = new Array();
		deleteArray.push(deletedRow.id);
		await Post(
			{
				ids: deleteArray,
			},
			Post_DeleteDue_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Dues deleted Successfully",
						"Dues deleted Successfully",
						"Dues deleted Successfully"
					),
					{
						variant: "success",
					}
				);
				//localhost:3000/dues/duesGenerated
				setRefResh(true);
				setIsLoading(false);
				setOpenDeleteDialog(false);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Can't delete dues",
						"Can't delete dues",
						"Can't delete dues"
					),
					{
						variant: "Error",
					}
				);
			}
		);
	};

	const handleDeleteRecovery = () => {
		setIsLoading(true);
		Post(
			{
				id: deletedRow.id,
			},
			Post_DeleteRecoveryProcedure_URL,
			null,
			(resp) => {
				enqueueSnackbar("Recovery Procedure Deleted Successfully", {
					variant: "success",
				});
				setRefResh(true);
				setIsLoading(false);
				setOpenDeleteDialog(false);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	const postRecoveryProcedure = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_StartRecoveryProcedure_URL,
			null,
			(resp) => {
				setRefResh(true);
				enqueueSnackbar(
					resp.data ? resp.data : "Recovery Procedure Added successfully",
					{
						variant: "success",
					}
				);
				setIsLoading(false);
				setStartRecoveryDialog(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};
	const upDateRecoveryProcedure = (payload) => {
		setIsLoading(true);

		Post(
			payload,
			Post_UpdateRecoveryProcedure_URL,
			null,
			(resp) => {
				enqueueSnackbar("Update Recovery Procedure Successfully", {
					variant: "success",
				});
				setRefResh(true);
				setStartRecoveryDialog(false);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	return (
		<div>
			<TableComponent
				dense
				loading={isLoading}
				rows={
					dataType === "0"
						? indexationList
						: dataType === "1"
						? recoveryList?.data
						: dataType === "2"
						? dueList?.data
						: []
				}
				colums={
					dataType === "0"
						? columns
						: dataType === "1"
						? recoveryColumns
						: dataType === "2"
						? duesColumns
						: []
				}
				count={
					dataType === "0"
						? indexationListCount?.totalCount
						: dataType === "1"
						? recoveryList?.totalCount
						: dataType === "2"
						? dueList?.totalCount
						: []
				}
				viewSearch
				viewConfirmAll={"true"} //Show the ConfirmAll button on Table
				onConfirmAll={confirmDialog} // show dialog when confirmAll button clicked
				disableConfirmAll={
					dataType === "0" && indexationListCount?.totalCount == 0
						? true
						: dataType === "1" && recoveryList?.totalCount == 0
						? true
						: dataType === "2" && dueList?.totalCount == 0
						? true
						: false
				}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				onSearch={(searchString) => handleSearchappartment(searchString)}
				enableCheckbox={true} // Enable checkboxes
				onSelectionChecked={(row) => handleSelectionChecked(row.id)} // Callback for selection
				onSelectionUnChecked={(row) => handleSelectionUnChecked(row.id)} // Callback for deselection
				onSelectionChange={(row) => {
					handleSelectAll(row);
				}}
				selected={selectRow}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				actions={
					dataType == 1
						? [
								{
									component: (rowData) => (
										<Tooltip
											title={getTranslation(
												"Edit Recovery",
												"Edit Recovery",
												"Edit Recovery"
											)}
										>
											<EditButton
												onClick={() => {
													onEditRecovery(rowData);
												}}
											/>
										</Tooltip>
									),
								},
						  ]
						: ""
				}
			/>

			<AlertDialog
				open={isOpenDialog}
				onClose={() => setIsOpenDialog(false)}
				onSubmit={handleConfirmIndexation}
				description={
					dataType == 1
						? getTranslation(
								"Are you sure you want to start recovery procedure for selected one?",
								"Are you sure you want to start recovery procedure for selected one?",
								"Are you sure you want to start recovery procedure for selected one?"
						  )
						: dataType == 0
						? getTranslation(
								"Are you sure you want to apply indexation on selected one?",
								"Are you sure you want to apply indexation on selected one?",
								"Are you sure you want to apply indexation on selected one?"
						  )
						: dataType == 2
						? getTranslation(
								"Are you sure you want to confirm selected dues",
								"Are you sure you want to confirm selected dues",
								"Are you sure you want to confirm selected dues"
						  )
						: ""
				}
				buttonTitle={getTranslation("Confirm", "Confirm", "Confirm")}
			/>

			<DialogComponent
				fullWidth
				maxWidth="lg"
				title={getTranslation(
					"Start Recovery Procedure",
					"Start Recovery Procedure",
					"Start Recovery Procedure"
				)}
				open={startRecoveryDialog}
				onClose={() => {
					setStartRecoveryDialog(false);
				}}
			>
				<RecoveryProcedureStepper
					// tenantList={tenantList}
					// tenantDetailLis={tenantDetailList}
					// tenantData={recoveryInfo}
					isEdit={isEdit}
					editRecoveryData={editRecoveryData}
					onClose={() => setStartRecoveryDialog(false)}
					handleSubmit={postRecoveryProcedure}
					onUpdate={upDateRecoveryProcedure}
					isLoading={isLoading}
				/>
			</DialogComponent>

			<AlertDialog
				open={openDeleteDialog}
				deleting={isLoading}
				onClose={() => setOpenDeleteDialog(false)}
				onSubmit={(row)=> dataType=='2' ? handleDeleteDue(row) : handleDeleteRecovery(row)}
			/>
		</div>
	);
};

export default DashBoardDataTable;

const MyFied = ({ row, selected, handlePercentageChange }) => {
	const [percentage, setPercentage] = useState(row.percentage);
	const [newRent, setNewRent] = useState(row.newRent);
	const handleChange = (newPercentage) => {
		let Percent = newPercentage / 100;
		let a = row.newProposal - row.oldRent;
		let b = a * Percent;
		let c = b + row.oldRent;
		setNewRent(c);

		setPercentage(newPercentage);
	};
	return (
		<Grid container spacing={3}>
			<Grid item lg={6} md={6} sm={6}>
				<TextField
					id="percentage"
					type="number"
					variant="outlined"
					style={{
						width: "100%",
					}}
					hiddenLabel
					// defaultValue="Small"
					focused={row.id == selected.id}
					size="small"
					inputProps={{ min: 0, max: 100 }}
					onChange={(e) => {
						if (e.target.value > 100) e.preventDefault();
						else handleChange(e.target.value);
					}}
					value={percentage}
					onBlur={(e) => handlePercentageChange(row, percentage, newRent)}
					disabled={row.newIndex > 0 && row.oldRent > 0 ? false : true}
				/>
			</Grid>

			<Grid item lg={6} md={6} sm={6}>
				<TextField
					id="newRent"
					// type="number"
					variant="outlined"
					style={{ width: "100%" }}
					hiddenLabel
					value={format("number", newRent)}
					onChange={(e) => setNewRent(e.target.value)}
					size="small"
					onBlur={(e) => handlePercentageChange(row, percentage, newRent)}
					disabled={row?.newIndex > 0 && row?.oldRent > 0 ? true : false}
				/>
			</Grid>
		</Grid>
	);
};
