import React, { Suspense, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Auth from "./components/Auth";
import AuthGuard from "./components/AuthGuard";
//Website Layouts
import AppLayout from "./layout";

//Auth Views
import Login from "./views/auth/Login";
import ForgetPassword from "./views/auth/ForgetPassword";

//Account Views
import MyAccount from "./views/account/myAccount";

//importing imp Views
import { Buildings } from "./views/buildings";
import { Appartments } from "./views/buildings/component/apartments";
import { Tanents } from "./views/tanents";
import { Details } from "./views/tanents/components/details";
import { Dues } from "./views/dues";
import { Bank } from "./views/bank";
import { Suppliers } from "./views/suppliers";
import { PreviousDues } from "./views/dues/components/previousDues";
import { Dashboard } from "./views/dashboard";
import {
	AllBuildingsReport,
	BuildingReport,
} from "./views/buildings/component/reports";
import { BankSettingList } from "./views/setting/bankSettings/bankSettingList";
import { SettingList } from "./views/setting/settingsList";
import { TenantRecovery } from "./views/tanents/components/recoveryprocedure";
import PhoneCallPublicScreen from "./views/tanents/components/recoveryprocedure/details/components/phoneCallPublicScreen";
import { UploadBankDocumentDialog } from "./views/bank/components/bankUploadDocument/bankUploadDocument";
import RecoveryTenantDetails from "./views/tanents/components/recoveryprocedure/details/components/recoveryTenantDetails";
import Indexation from "./views/dues/components/indexation";
import Registration from "./views/auth/Registration";
import Upgrade from "./Upgrade";
import Thankyou from "./views/tanents/components/recoveryprocedure/details/components/thankyou";

const routesConfig = [
	{
		path: "/",
		exact: true,
		component: () => <Login />,
	},
	{
		path: "/login",
		exact: true,
		component: () => <Login />,
	},
	{
		path: "/upgrade",
		exact: true,
		component: () => <Upgrade />,
	},
	{
		path: "/registration",
		exact: true,
		component: () => <Registration />,
	},
	{
		path: "/registration/:myappId/:packageId",
		exact: true,
		component: () => <Registration />,
	},
	{
		path: "/login/:token",
		exact: true,
		component: () => <Login autologin />,
	},
	{
		path: "/forget_password",
		exact: true,
		component: () => <ForgetPassword />,
	},
	{
		path: "/public/phonecalls/:tenantId",
		exact: true,
		component: () => <PhoneCallPublicScreen />,
	},
	{
		path: "/public/thankyou",
		exact: true,
		component: () => <Thankyou />,
	},
	{
		path: "/",
		layout: AppLayout,
		guard: Auth,
		childrens: [
			{
				path: "/",
				exact: true,
				component: () => <Dashboard />,
			},
			{
				path: "/dashboard",
				exact: true,
				component: () => <Dashboard />,
			},
			//   {
			//     path: "/upgrade",
			//     exact: true,
			//     component: () => <Upgrade />,
			//   },
			{
				path: "/account/profile",
				exact: true,
				component: () => <MyAccount />,
			},
			{
				path: "/buildings",
				exact: true,
				component: () => <Buildings />,
			},
			{
				path: "/buildings/:buildingId/apartments",
				exact: true,
				component: () => <Appartments />,
			},

			////  Buolding Report URLs ///
			{
				path: "/buildingReport",
				exact: true,
				component: () => <BuildingReport />,
			},
			{
				path: "/allBuildingsReport",
				exact: true,
				component: () => <AllBuildingsReport />,
			},
			{
				path: "/tanents",
				exact: true,
				component: () => <Tanents />,
			},
			{
				path: "/tanents/details/:tenantId/details",
				exact: true,
				component: () => <Details />,
			},
			{
				path: "/tanents/details/:tenantId/contracts",
				exact: true,
				component: () => <Details />,
			},
			{
				path: "/tanents/details/:tenantId/banks",
				exact: true,
				component: () => <Details />,
			},
			{
				path: "/tanents/details/:tenantId/dues",
				exact: true,
				component: () => <Details />,
			},
			{
				path: "/tanents/details/:tenantId/notes",
				exact: true,
				component: () => <Details />,
			},
			{
				path: "/tanents/recoveryprocedure",
				exact: true,
				component: () => <TenantRecovery />,
			},
			{
				path: "/tanents/recoveryprocedure/:recoveryId/details",
				exact: true,
				component: () => <RecoveryTenantDetails />,
			},
			{
				path: "/dues",
				childrens: [
					{
						path: "/dues/duesGenerated",
						exact: true,
						component: () => <Dues />,
					},
					{
						path: "/dues/previousDues",
						exact: true,
						component: () => <PreviousDues />,
					},
					// {
					// 	path: "/dues/indexation",
					// 	exact: true,
					// 	component: () => <Indexation />,
					// },
				],
			},

			{
				path: "/bank",
				exact: true,
				component: () => <Bank />,
			},
			{
				path: "/bank/uploaddocument/:bankId",
				exact: true,
				component: () => <UploadBankDocumentDialog />,
			},
			{
				path: "/suppliers",
				exact: true,
				component: () => <Suppliers />,
			},

			{
				path: "/settings/email",
				exact: true,
				component: () => <SettingList />,
			},
			{
				path: "/settings/bank",
				exact: true,
				component: () => <BankSettingList />,
			},
			{
				component: () => <div>Not Found</div>,
			},
		],
	},

	{
		component: () => <div>Not Found</div>,
	},
];

const renderRoutes = (routes) => {
	return routes ? (
		<Suspense fallback={<h1>loading...</h1>}>
			<Switch>
				{routes.map((route, i) => {
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;
					const Bootstrap = route.bootstrap || Fragment;

					return (
						<Route
							exact={route.exact}
							path={route.path}
							key={i}
							render={(props) => (
								<Layout>
									<Guard>
										<Bootstrap>
											{route.childrens ? (
												renderRoutes(route.childrens)
											) : (
												<Component {...props} />
											)}
										</Bootstrap>
									</Guard>
								</Layout>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	) : null;
};

const AppRoutes = () => {
	return renderRoutes(routesConfig);
};

export default AppRoutes;
