import React from "react";
import Table from "../../../components/table";
import { useState, useEffect, useRef } from "react";
import {
	DeleteButton,
	EditButton,
	PrintButton,
	ReminderButton,
	SendSMSButton,
} from "../../../components/ButttonsWithIcons";

import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ReminderTemplateDialog } from "./reminderTemplateDialog";
import { getTranslation } from "../../../heplers/translationHelper";

import {
	Get_SuppliersReport_URL,
	Get_SendManualReminders_URL,
	Get_AllFundActivitiesWithPagination_URL,
	Delete_Tenant_URL,
	Post_EmailCallReminder_URL,
	Post_StartRecoveryProcedure_URL,
	Get_GetPackageInfo_URL,
	Get_Tanenet_URL,
	Get_AllContractsWithPagination_URL,
	Get_TenantDuesSummary_URL,
	GET_VerifyTenantPhoneNumber_URL,
	Get_FundActivities_URL,
} from "../../../constants/apiUrls";
import {
	APP_MANAGER_SERVER_GETSMS_URL,
	APP_MANAGER_SERVER_SENDSMS_URL,
} from "../../../constants";
import { useReactToPrint } from "react-to-print";
import { DateIntervalDialog } from "../../../components/DateIntervalDialog";
import { useSnackbar } from "notistack";
import { AllTenantReport } from "./reports";
import { ReportAllIcon } from "../../../components/icons";
import { Get, GetPackageInfo, Post } from "../../../actions";
import { format } from "../../../heplers/format";
import MailIcon from "@material-ui/icons/Mail";
import WarningIcon from "@material-ui/icons/Warning";
import {
	DeleteOutline,
	DeleteSharp,
	Notifications,
	Print,
} from "@material-ui/icons";
import { SMSReminderTemplateDialog } from "./smsReminderTemplateDialog";
import { PrintDialog } from "./details/components";
import { ReportSingleIcon } from "../../../components/icons";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import PrintLetterDialog from "./details/components/printLetterDialog";
import CallIcon from "@material-ui/icons/Call";
import CachedIcon from "@material-ui/icons/Cached";
import DialogComponent from "../../../components/Dialog";
import RecoveryProcedureStepper from "./recoveryprocedure/components/recoveryProcedureStepper";
import CardComponent from "../../../components/Card";
import { Upgrade } from "../../common/Upgrade";
import authUtils from "../../../utils/authUtils";
import authService from "../../../utils/authUtils";
import axios from "axios";
import { useSelector } from "react-redux";
import { PhoneNumberUtil } from "google-libphonenumber";

const onHold = [
	{
		id: "name",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Name", "Name", "Name"),
	},
	{
		id: "reservedContractStartDate",
		label: getTranslation("Start Date", "Date de début", "Anfangsdatum"),
		format: "date",
	},
	{
		id: "apartmentNo",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Appartment", "Appartement", "Wohnung"),
	},
	{
		id: "buildingName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Building", "Imeuble", "Gebäude"),
	},
	{
		id: "statusText",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Status", "Statut", "Status"),
		component: (row) => {
			return <Typography>{row?.row?.contracts[0]?.statusText}</Typography>;
		},
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Actions", "Actions", "Aktionen"),
	},
];

const Tanents = ({
	loading,
	printLoader,
	rows,
	onSendReminder,
	onFilter,
	sendManually,
	onTenantChange,
	onCreate,
	onEditTenant,
	onPrint,
	onRefresh,
	change,
	setIsRefresh,
}) => {
	const columns = [
		{
			id: "name",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Name", "Name", "Name"),
		},
		{
			id: "apartmentNo",
			label: getTranslation("Appartment", "Appartement", "Wohnung"),
			format: "left",
		},
		{
			id: "lastPaymentDate",
			label: getTranslation(
				"Last Payment",
				"Dernier payment",
				"Letzte Zahlung"
			),
			format: "date",
			component: ({ row }) => {
				if (row.isPaymentDoneInLastMonth == false) {
					return (
						<Typography style={{ color: "red" }}>
							{format("date", row.lastPaymentDate)}
						</Typography>
					);
				}
				return (
					<Typography style={{ color: "green" }}>
						{format("date", row.lastPaymentDate)}
					</Typography>
				);
			},
		},
		{
			id: "accountBalance",
			label: getTranslation("Balance", "Solde", "Saldo"),
			format: "number",
			align: "right",
			component: (row) => {
				return (
					<Typography>{format("number", row?.row?.accountBalance)}</Typography>
				);
			},
		},
		{
			id: "telephone",
			label: getTranslation("Phone", "Phone", "Phone"),
			format: "number",
			align: "right",
			component: (row) => {
				return (
					<div style={{ display: "flex", justifyContent: "right" }}>
						<Typography
							style={{
								color: row?.row?.verifyNumber ? "black" : "red",
								paddingTop: "10px",
								paddingRight: "11px",
							}}
						>
							{format("number", row?.row?.telephone)}
						</Typography>
						{!row?.row?.verifyNumber && (
							<IconButton
								onClick={() => {
									setVerifyDialog(false);
								}}
							>
								{/* <WarningIcon size="small" style={{ color: "red" }} /> */}
								<IconButton
									style={{ padding: "0px" }}
									onClick={(e) => {
										e.stopPropagation();
										setTargetRow(row);
										setVerifyDialog(true);
									}}
								>
									<WarningIcon size="small" style={{ color: "orange" }} />
								</IconButton>
							</IconButton>
						)}
					</div>
				);
			},
		},

		{
			id: "actions",
			label: getTranslation("Action", "Action", "Action"),
			align: "right",
		},
	];

	const userData = useSelector((state) => state.auth.user);

	const customerId = authService.getCustomerId();
	const [tenantStatus, setTenantStatus] = useState("active");
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let pageSize = localStorage.getItem("sizeTent");
	if (pageSize == null) {
		localStorage.setItem("sizeTent", 10);
		pageSize = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(pageSize);
	const [manualSending, setManualSending] = useState(false);
	const [sendReminderDialog, setSendReminderDialog] = useState(false);
	const [sendSMSReminderDialog, setSendSMSReminderDialog] = useState(false);
	const [sendPostReminderDialog, setSendPostReminderDialog] = useState(false);
	const [reminderTenant, setReminderTenant] = useState();
	const [sortDirection, setSortDirecion] = useState("asc");
	const [sortBy, setSortBy] = useState("name");
	const { enqueueSnackbar } = useSnackbar();

	const [reportModel, setReportModel] = useState();

	const [dateInterval, setDateInterval] = useState(false);
	const componentRef = useRef();
	const sendByPostRef = useRef();

	//about recovery procedure states
	const [startRecoveryDialog, setStartRecoveryDialog] = useState(false);
	const [tenantInfo, setTenantInfo] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [reminderBtnLoading, setReminderBtnLoading] = useState(false);
	const [sendManualReminder, setSendManualReminder] = useState(false);
	const [upGradeDialogOpen, setUpGradeDialogOpen] = useState(false);
	const [appKeys, setAppKeys] = useState();

	// printing reprot
	const [activeContract, setActiveContract] = useState(null);
	const [tenant, setTenant] = useState();
	const [printDueList, setPrintDueList] = useState([]);
	const [tenantDuesSummary, setTenanDuesSummary] = useState();
	const [individaulTenantId, setIndividualTenat] = useState();
	const [dueList1, setDueList1] = useState([]);
	const [filterItems, setFilterItems] = useState({
		searchString: "",
		pageNumber: 0,
		pageSize: 10,
		sort: "date",
		sortDirection: 1,
		tenantId: individaulTenantId,
		duescategory: "all",
	});
	const componentRef1 = useRef();
	const [verified, setVerified] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [btnVisiable, setBtnVisiable] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [verifyDialog, setVerifyDialog] = useState(false);
	const [numberVerification, setNumberVerification] = useState(false);
	const [numberVerificationTrue, setNumberVerifactionTrue] = useState(false);
	const [verificationDone, setVericationDone] = useState(false);
	const [verifedPhoneNumber, setVerifiedPhoneNumber] = useState();
	const [disableHanlder, setDisableHandler] = useState(true);
	const [verifyButtonText, setVerifyButtonText] = useState("Verify Number");
	const [verifyNumberBit, setVerifyNumberBit] = useState(false);
	const [TargetRow, setTargetRow] = useState();
	const [verificationLoading, setVerificationLoading] = useState(false);

	useEffect(() => {
		getTenant(individaulTenantId);
		getTenantDuesSummary();
		loadTenantContracts();
		loadDues1(filterItems);
		loadAllDues(filterItems);
	}, [individaulTenantId]);

	const handleClickPrint = (id) => {
		setIndividualTenat(id);
		setTimeout(() => {
			handlePrintIndividual();
		}, 4000);
		console.log(id);
	};

	const handlePrintIndividual = useReactToPrint({
		documentTitle: getTranslation(
			"Tenant Report",
			"Tenant Report",
			"Tenant Report"
		),
		content: () => componentRef1.current,
	});

	const loadTenantContracts = () => {
		Get(
			{ tenantId: individaulTenantId },
			Get_AllContractsWithPagination_URL,
			null,
			(resp) => {
				let active = resp?.data?.data.find((x) => x.status == 2);
				if (active) {
					setActiveContract(active);
				} else {
					setActiveContract(resp?.data?.data.find((x) => x.status == 0));
				}
			},
			(error) => {
				console.log("error");
			}
		);
	};

	const getTenant = (values) => {
		Get(
			{ tenantId: values },
			Get_Tanenet_URL,
			null,
			(resp) => {
				setTenant(resp?.data);
			},
			(error) => {
				console.log("error");
			}
		);
	};

	const getTenantDuesSummary = () => {
		Get(
			{ tenantId: individaulTenantId },
			Get_TenantDuesSummary_URL,
			null,
			(res) => {
				setTenanDuesSummary(res?.data);
			},
			(err) => {
				console.log("error");
			}
		);
	};

	const loadDues1 = (values) => {
		setFilterItems(values);

		Get(
			{
				searchString: "",
				pageNumber: 0,
				pageSize: 10,
				sort: "date",
				sortDirection: 1,
				tenantId: individaulTenantId,
				duescategory: "all",
			},
			Get_AllFundActivitiesWithPagination_URL,
			null,
			(resp) => {
				setDueList1(resp?.data);
			},
			(error) => {
				console.log("error");
			}
		);
	};

	// load all dues without pagination
	const loadAllDues = (values) => {
		values.pageSize = 100;
		values.sortDirection = 1;
		Get(
			{
				tenantId: individaulTenantId,
			},
			Get_FundActivities_URL,
			null,
			(resp) => {
				setPrintDueList(resp);
			},
			(error) => {
				console.log("error");
			}
		);
	};
	//print end

	const handlePrintSupplierReport = useReactToPrint({
		documentTitle: getTranslation(
			" All Suppliers Report ",
			" All Suppliers Report ",
			" All Suppliers Report "
		),
		content: () => componentRef.current,
	});

	useEffect(() => {
		onFilter({
			pageNumber: page,
			pageSize: rowsPerPage,
			status: tenantStatus,
			sortDirection: sortDirection == "asc" ? 0 : 1,
			sort: sortBy,
			searchString: searchString,
		});
	}, [page, searchString, rowsPerPage, tenantStatus, sortDirection, sortBy]);

	useEffect(() => {
		GetPackageInfo().then((res) => {
			setAppKeys(res?.data?.appKeys);
		});
	}, []);

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeTent", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeTent"));
		setPage(0);
	};

	const handleSearchTanents = (searchString) => {
		setSearchString(searchString);
	};

	const handleSendReminder = (row) => {
		setManualSending(true);
		setSendReminderDialog(true);
		setReminderTenant(row);
	};

	const handleSendSMSReminder = (row) => {
		setSendSMSReminderDialog(true);
		setReminderTenant(row);
	};

	const handleSendPostReminder = (row) => {
		setSendPostReminderDialog(true);
		setReminderTenant(row);
	};

	//send call reminder to tenant
	const handleCallReminder = (row) => {
		Post(
			{
				tenantId: row.id,
			},
			Post_EmailCallReminder_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Call Reminder Send successfully.",
						"Call Reminder Send successfully.",
						"Call Reminder Send successfully."
					),
					{ variant: "success" }
				);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin"
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	//start recovery procedure against selected tenant
	const handleRecoveryProcedure = (row) => {
		setTenantInfo(row);
		setStartRecoveryDialog(true);
	};

	/////....This is all tenant report section start...//////
	const handleSubmitDateInterval = (values, actions) => {
		Get(
			{ to: values.endDate, from: values.startDate },
			Get_SuppliersReport_URL,
			null,
			(resp) => {
				setReportModel(resp?.data);
				setDateInterval(false);
				handlePrintSupplierReport();
				actions.setSubmitting(false);
			},
			(error) => {
				enqueueSnackbar(
					error?.data
						? getTenant(error?.data, error?.data, error?.data)
						: getTranslation(
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin"
						  ),
					{
						variant: "error",
					}
				);
				actions.setSubmitting(false);
			}
		);
	};

	/////....This is all tenant report section ends...//////
	const SendMenualReminder = (values, actions) => {
		setSendManualReminder(false);
		enqueueSnackbar(
			getTranslation(
				"Your request is processing",
				"Your request is processing",
				"Your request is processing"
			),
			{
				variant: "success",
			}
		);
		Get(values, Get_SendManualReminders_URL, null);
	};

	const [dueList, setDueList] = useState([]);
	const [sendByPostMessage, setSendByPostMessage] = useState("");

	const loadDues = () => {
		Get(
			{ tenantId: reminderTenant.id },
			Get_FundActivities_URL,
			null,
			(resp) => {
				// setSendPostReminderDialog(true);
				// setDuesLoadign(false);
				setDueList(resp);
				setTimeout(() => {
					handlePrint();
				}, 1000);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Something went wrong. Please contact with admin",
						"Something went wrong. Please contact with admin",
						"Something went wrong. Please contact with admin"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const [deleting, setDeleting] = useState(null);

	const handleDeleteTenant = (row) => {
		setDeleting(row);
		Get(
			{ tenantId: row.id },
			Delete_Tenant_URL,
			null,
			(resp) => {
				setDeleting(null);
				if (onRefresh) onRefresh();
				enqueueSnackbar(
					getTranslation(
						"Tenant deleted successfully.",
						"Tenant deleted successfully.",
						"Tenant deleted successfully."
					),
					{
						variant: "success",
					}
				);
			},
			(error) => {
				setDeleting(null);

				enqueueSnackbar(
					error?.data
						? getTranslation(error?.data, error?.data, error?.data)
						: getTranslation(
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin"
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handlePrint = useReactToPrint({
		documentTitle: getTranslation(
			"Tenant Report",
			"Tenant Report",
			"Tenant Report"
		),
		content: () => sendByPostRef.current,
	});
	//post recovery procedure
	const postRecoveryProcedure = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_StartRecoveryProcedure_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					resp.data
						? getTranslation(resp.data, resp.data, resp.data)
						: getTenant(
								"Recovery Procedure added successfully.",
								"Recovery Procedure added successfully.",
								"Recovery Procedure added successfully."
						  ),
					{
						variant: "success",
					}
				);
				setIsLoading(false);
				setStartRecoveryDialog(false);
			},
			(err) => {
				enqueueSnackbar(
					getTranslation(err?.data, err?.data, err?.data)
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	const MenuButton = ({ row }) => {
		const [reminderEl, setReminderEl] = useState(null);
		const handleOpen = (event) => {
			setReminderEl(event.currentTarget);
		};

		const handleClose = () => {
			setReminderEl(null);
		};
		return (
			<>
				<IconButton onClick={handleOpen} size="small">
					<Notifications />
				</IconButton>
				<Menu
					anchorEl={reminderEl}
					open={Boolean(reminderEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem onClick={() => handleSendReminder(row)}>
						<ReminderButton />
						&nbsp;
						{getTranslation("Email", "Email", "Email")}
					</MenuItem>
					<MenuItem onClick={() => handleSendSMSReminder(row)}>
						<SendSMSButton />
						&nbsp;
						{getTranslation("SMS", "SMS", "SMS")}
					</MenuItem>
					<MenuItem>
						<IconButton
							size="small"
							variant="contained"
							onClick={() => handleSendPostReminder(row)}
							color="red"
						>
							<Print />
							&nbsp;
							{getTranslation("Post", "Post", "Post")}
						</IconButton>
					</MenuItem>
					<MenuItem>
						<IconButton
							size="small"
							variant="contained"
							onClick={() => handleCallReminder(row)}
						>
							<CallIcon />
							&nbsp;
							{getTranslation("Call", "Call", "Call")}
						</IconButton>
					</MenuItem>
					<MenuItem>
						<IconButton
							size="small"
							variant="contained"
							onClick={() => handleRecoveryProcedure(row)}
						>
							<CachedIcon />
							&nbsp;
							{getTranslation("Recovery", "Recovery", "Recovery")}
						</IconButton>
					</MenuItem>
				</Menu>
			</>
		);
	};

	const getSms = (payload, id) => {
		var tenantIdTochange = id;
		Get(
			{ SmsSid: payload },
			APP_MANAGER_SERVER_GETSMS_URL,
			null,
			(resp) => {
				switch (5) {
					case 1:
						callGetSms(payload,id);
						break;
					case 4:
						enqueueSnackbar("Verification faild please Verify again", {
							variant: "error",
						});
						setVerified(false);
						setIsDisable(true);
						setVerificationLoading(false);
						setVerifyDialog(false);
						setVericationDone(false);
						break;
					case 5:
						Get(
							{ tenantId: tenantIdTochange },
							GET_VerifyTenantPhoneNumber_URL,
							null,
							(resp) => {
								setVerifyNumberBit(true);
								setVerificationLoading(false);
								setVerifyDialog(false);
								enqueueSnackbar("Your phone number is verified", {
									variant: "success",
								});
								change();
							},
							(error) => {
								//setDeleting(null);
								setVerificationLoading(false);
								setVerifyDialog(false);
								enqueueSnackbar(
									getTranslation(error?.data, error?.data, error?.data),
									{
										variant: "error",
									}
								);
							}
						);
						break;
					case 6:
						enqueueSnackbar(
							"Your phone number is not verified.please try another number",
							{ variant: "error" }
						);
						setVerified(false);
						setIsDisable(true);
						break;
					default:
						callGetSms(payload,id);

						break;
				}
			},
			(error) => {
				enqueueSnackbar("verification failed", { variant: "error" });
				setVerificationLoading(false);
				setVerifyDialog(false);
				setVericationDone(false);
			}
		);
	};

	const phoneUtil = PhoneNumberUtil.getInstance();
	const isPhoneValid = (phone) => {
		try {
			return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
		} catch (error) {
			return false;
		}
	};

	const handlePhoneChange = (value) => {
		setIsValid(isPhoneValid(value));
		setPhoneNumber(value);
	};

	const callGetSms = (smsSid, id) => {
		setTimeout(getSms(smsSid, id), 3000);
	};

	const sendSms = (row) => {
		console.log("ghhh", row?.row);
		if (!userData?.smsCredit) {
			console.log("no credit", row?.row);
			return;
		}

		let payload = {
			phone: row?.row?.telephone,
			message: "Congrations! Your Number is Verified for Clic Rent",
			customerId: customerId,
		};
		setVerificationLoading(true);

		//callGetSms("data", row?.row?.id);

		//succees case
		// if (true) {
		//   setNumberVerifactionTrue(true);
		//   setVerifyDialog(false);
		//   setVericationDone(true);
		//   setVerifiedPhoneNumber(row?.row?.telephone);
		//   setDisableHandler(true);
		//   setVerifyButtonText("Verified Number");
		//   setVerifyNumberBit(true);

		//   Get(
		//     { tenantId: row?.row?.id },
		//     GET_VerifyTenantPhoneNumber_URL,
		//     null,
		//     (resp) => {
		//       console.log(
		//         "GET_VerifyTenantPhoneNumbe_URL Response..",
		//         row?.verifyNumber
		//       );
		//       setNumberVerifactionTrue(true);
		//       setVerifyDialog(false);
		//       setVericationDone(true);
		//       setVerifiedPhoneNumber(row?.telephone);
		//       setDisableHandler(true);
		//       setVerifyButtonText("Verified Number");
		//       setVerifyNumberBit(true);
		//       change();
		//     },
		//     (error) => {
		//       //setDeleting(null);

		//       enqueueSnackbar(
		//         getTranslation(error?.data, error?.data, error?.data),
		//         {
		//           variant: "error",
		//         }
		//       );
		//     }
		//   );
		// }

		//failure case
		// if (true) {
		// 	setNumberVerification(true);
		// 	setVerifyDialog(false);
		// 	setVericationDone(false);
		//setVerifyButtonText("Verify Number");
		//setVerifyNumberBit(false)
		// }

		Post(
			payload,
			APP_MANAGER_SERVER_SENDSMS_URL,
			null,
			(resp) => {
				if (resp?.data == null) {
					enqueueSnackbar("Twilio cannot send your message please try again", {
						variant: "error",
					});
				} else {
					//setVerifyDialog(false);

					callGetSms(resp?.data, row?.row?.id);
				}
			},
			(error) => {
				enqueueSnackbar("Twilio verification failed please try again", {
					variant: "error",
				});
				setNumberVerification(true);
				setVerificationLoading(false);
				setVerifyDialog(false);
				setVericationDone(false);
				setVerifyButtonText("Verify Number");
				setVerifyNumberBit(false);
			}
		);
	};

	const controllerDiableFuction = (number) => {
		if (number.length > 5) {
			setDisableHandler(false);
			setVerifyButtonText("Verify Number");
		}
		if (number.length > 5) {
			console.log("isnide number", number);
			console.log("verified numbner", verifedPhoneNumber);
			if (number == verifedPhoneNumber) {
				setDisableHandler(true);
				setVerifyButtonText("Verified Number");
				setVerifyNumberBit(true);
				return;
			}
			if (verifedPhoneNumber && number != verifedPhoneNumber) {
				setDisableHandler(false);
				setVerifyButtonText("Verify Number");
				setVerifyNumberBit(false);
				return;
			}
		}
	};

	return (
		<CardComponent>
			<CardContent>
				{/* Start -> Tenants Active InActive On-Hold Toggle Buttons*/}
				<ToggleButtonGroup
					size="small"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					id="tenantStatus"
					//defaultChecked={tenantStatus}
					value={tenantStatus}
					exclusive
					onChange={(event, tenantStatus) => {
						if (tenantStatus !== null) {
							setTenantStatus(tenantStatus);
						}
					}}
				>
					<ToggleButton
						value={"active"}
						aria-label="left aligned"
						style={{
							backgroundColor:
								tenantStatus === "active" ? "rgb(230,231,246)" : "",
						}}
					>
						<Typography>
							{getTranslation("active", "actif", "aktiv")}
						</Typography>
					</ToggleButton>
					<ToggleButton
						value={"inactive"}
						aria-label="centered"
						style={{
							backgroundColor:
								tenantStatus === "inactive" ? "rgb(230,231,246)" : "",
						}}
					>
						<Typography>
							{getTranslation("inactive", "inactif", "inaktiv")}
						</Typography>
					</ToggleButton>
					<ToggleButton
						value={"onhold"}
						aria-label="centered"
						style={{
							backgroundColor:
								tenantStatus === "onhold" ? "rgb(230,231,246)" : "",
						}}
					>
						<Typography>
							{getTranslation("On Hold", "En attente", "In Wartestellung")}
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
				{/* End -> Tenants Active InActive On-Hold Toggle Buttons*/}
				<br />
				{/* Print and Sendreminder Button */}
				<Box
					style={{
						display: "flex",
						alignItems: "flex-end",
						justifyContent: "flex-end",
						marginBottom: "1.5rem",
					}}
				>
					<ButtonWithLoading
						onClick={() => onPrint()}
						style={{
							borderRadius: "25px",
							backgroundColor: "rgb(229,60,22)",
							color: "white",
						}}
						variant="contained"
						title={
							<>
								{" "}
								<Print /> &nbsp;&nbsp;{" "}
								{getTranslation("Print", "Print", "Print")}
							</>
						}
						size="medium"
						loading={printLoader}
					/>
					&nbsp;&nbsp;
					<Button
						onClick={() => setSendManualReminder(true)}
						style={{
							borderRadius: "25px",
							backgroundColor: "rgb(229,60,22)",
						}}
						variant="contained"
						color="primary"
						loading={reminderBtnLoading}
					>
						<MailIcon />
						&nbsp;&nbsp;{" "}
						{getTranslation(
							"Send Reminders",
							"Send Reminders",
							"Send Reminders"
						)}
					</Button>
				</Box>
				{sendManualReminder ? (
					<DialogComponent
						open={sendManualReminder}
						maxWidth={"xs"}
						fullWidth
						title={getTranslation(
							"Confirmation",
							"Confirmation",
							"Confirmation"
						)}
						onClose={() => setSendManualReminder(false)}
					>
						<Typography align="center">
							{getTranslation(
								"Are you sure you want to send reminders?",
								"Are you sure you want to send reminders?",
								"Are you sure you want to send reminders?"
							)}
						</Typography>
						<br />
						<div style={{ display: "flex", justifyContent: "center" }}>
							<ButtonWithLoading
								onClick={() => setSendManualReminder(false)}
								variant="contained"
								title={getTranslation("Cancel", "Cancel", "Cancel")}
								size="medium"
							/>
							&nbsp;&nbsp;{" "}
							<ButtonWithLoading
								onClick={() => SendMenualReminder()}
								style={{
									backgroundColor: "rgb(229,60,22)",
									color: "white",
								}}
								variant="contained"
								title={getTranslation("Confirm", "Confirm", "Confirm")}
								size="medium"
							/>
						</div>
						<br />
					</DialogComponent>
				) : (
					""
				)}
				{/* Print and Sendreminder Button */}

				{/* TenantList Table */}
				<Table
					loading={loading}
					dense
					title={getTranslation("Tenant List", "Tenant List", "Tenant List")}
					colums={tenantStatus === "onhold" ? onHold : columns}
					sortBy={sortBy}
					onSorting={(sort, direction) => {
						setSortBy(sort);
						setSortDirecion(direction);
					}}
					sortDirection={sortDirection}
					rows={rows?.data}
					count={rows?.totalCount}
					page={page}
					rowsPerPage={rowsPerPage}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					viewEdit
					onEdit={(row) => onTenantChange(row)}
					viewSearch
					viewCreate
					onCreate={() => {
						if (appKeys[2].value <= rows?.totalCount) {
							setUpGradeDialogOpen(true);
						} else {
							onCreate();
						}
					}}
					onSearch={(searchString) => handleSearchTanents(searchString)}
					actions={[
						{
							component: (row) => (
								<>
									{row.status === "onhold" || row.status === "inactive" ? (
										<EditButton onClick={() => onEditTenant(row)} />
									) : null}
								</>
							),
						},
						{
							component: (row) => (
								<IconButton
									size="small"
									variant="contained"
									style={{ color: "#f33333" }}
									onClick={() => handleClickPrint(row.id)}
								>
									<ReportSingleIcon color="red" style={{ color: "red" }} />
								</IconButton>
							),
						},
						{
							component: (row) => (
								<div>
									<MenuButton row={row} />
									{/* <DialogComponent
										title={getTranslation(
											"Number Verification",
											"Number Verification",
											"Number Verification"
										)}
										open={verifyDialog}
										onClose={() => {
											setVerifyDialog(false);
										}}
										//fullWidth
										maxWidth="sm"
									>
										{!userData?.smsCredit ? (
											<div>
												<Typography>
													{getTranslation(
														"For number Verification you need balance, kindly recharge your account",
														"For number Verification you need balance, kindly recharge your account",
														"For number Verification you need balance, kindly recharge your account"
													)}
												</Typography>
												<div
													style={{
														width: "100%",
														display: "flex",
														justifyContent: "center",
														margin: "10px 0px 10px 0px",
													}}
												>
													<ButtonWithLoading
														//disabled={isDisable}
														style={{
															borderRadius: "25px",
															backgroundColor: "#e65837",
														}}
														title={getTranslation(
															"Recharge Now",
															"Recharge Now",
															"Recharge Now"
														)}
														size="small"
														variant="contained"
														color="primary"
														//	loading={isLoading}
													/>
												</div>
											</div>
										) : (
											<div>
												{verificationDone ? (
													<Typography>
														{getTranslation(
															"Your Number is already Verifed",
															"Your Number is already Verifed",
															"Your Number is already Verifed"
														)}
													</Typography>
												) : (
													<>
														<Typography>
															{getTranslation(
																"This Number Verifcation will cost you some amount from your balance",
																"This Number Verifcation will cost you some amount from your balance",
																"This Number Verifcation will cost you some amount from your balance"
															)}
														</Typography>
														<div
															style={{
																width: "100%",
																display: "flex",
																justifyContent: "center",
																margin: "10px 0px 10px 0px",
															}}
														>
															<ButtonWithLoading
																//disabled={isDisable}
																style={{
																	borderRadius: "25px",
																	backgroundColor: "#e65837",
																}}
																title={getTranslation(
																	"Verify Now",
																	"Verify Now",
																	"Verify Now"
																)}
																size="small"
																variant="contained"
																color="primary"
																//	loading={isLoading}
																onClick={() => {
																	sendSms(row);
																}}
															/>
														</div>
													</>
												)}
											</div>
										)}
									</DialogComponent> */}
								</div>
							),
						},
						{
							component: (row) =>
								deleting && deleting?.id == row.id ? (
									<CircularProgress size="24px" />
								) : (
									<IconButton
										size="small"
										variant="contained"
										style={{ color: "#f33333" }}
										onClick={() => handleDeleteTenant(row)}
									>
										<DeleteSharp />
									</IconButton>
								),
						},
					]}
				/>
				{/* end TenantList Table */}

				<DialogComponent
					title={getTranslation(
						"Number Verification",
						"Number Verification",
						"Number Verification"
					)}
					open={verifyDialog}
					onClose={() => {
						setVerifyDialog(false);
					}}
					//fullWidth
					maxWidth="sm"
				>
					{!userData?.smsCredit ? (
						<div>
							<Typography>
								{getTranslation(
									"For number Verification you need balance, kindly recharge your account",
									"For number Verification you need balance, kindly recharge your account",
									"For number Verification you need balance, kindly recharge your account"
								)}
							</Typography>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									margin: "10px 0px 10px 0px",
								}}
							>
								<ButtonWithLoading
									//disabled={isDisable}
									style={{
										borderRadius: "25px",
										backgroundColor: "#e65837",
									}}
									title={getTranslation(
										"Recharge Now",
										"Recharge Now",
										"Recharge Now"
									)}
									size="small"
									variant="contained"
									color="primary"
									//loading={isLoading}
									onClick={() => window.open('https://admin.qollex.com/customerCredit', '_blank', 'noopener,noreferrer')}

								/>
							</div>
						</div>
					) : (
						<div>
							<Typography>
								{getTranslation(
									"This Number Verifcation will cost you some amount from your balance",
									"This Number Verifcation will cost you some amount from your balance",
									"This Number Verifcation will cost you some amount from your balance"
								)}
							</Typography>
							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									margin: "10px 0px 10px 0px",
								}}
							>
								<ButtonWithLoading
									//disabled={isDisable}
									style={{
										borderRadius: "25px",
										backgroundColor: "#e65837",
									}}
									title={getTranslation(
										"Verify Now",
										"Verify Now",
										"Verify Now"
									)}
									size="small"
									variant="contained"
									color="primary"
									loading={verificationLoading}
									onClick={() => {
										sendSms(TargetRow);
									}}
								/>
							</div>
						</div>
					)}
				</DialogComponent>

				{/* ////.....Report starts here ....//// */}
				<div style={{display:"none"}}>
					<DateIntervalDialog
						isOpen={dateInterval}
						onSubmit={(values, actions) => {
							handleSubmitDateInterval(values, actions);
						}}
						onClose={() => setDateInterval(false)}
					/>
				</div>
				<div style={{ display: "none" }}>
					<Report ref={componentRef} model={reportModel} />
				</div>

				<div style={{ display: "none" }}>
					<PrintDialog
						activeContract={activeContract}
						tenant={tenant}
						rows={printDueList}
						ref={componentRef1}
						tenantDuesSummary={tenantDuesSummary}
						filterItems={filterItems}
					/>
				</div>

				<div style={{ display: "none" }}>
					<PrintLetterDialog
						sendByPost
						activeContract={reminderTenant}
						tenant={reminderTenant}
						rows={dueList}
						// rows={printDueList}
						ref={sendByPostRef}
						model={reportModel}
						message={sendByPostMessage}
						filterItems={filterItems}
						tenantDuesSummary={tenantDuesSummary}
					/>
				</div>
				{/* ////.....Report ends here ....//// */}
				{sendReminderDialog || sendPostReminderDialog ? (
					<div>
						<ReminderTemplateDialog
							isOpen={sendReminderDialog || sendPostReminderDialog}
							onClose={() => {
								setSendReminderDialog(false);
								setSendPostReminderDialog(false);
							}}
							sendManually={manualSending}
							reminderToTenant={reminderTenant}
							sendByPost={sendPostReminderDialog}
							onSendByPost={(message) => {
								setSendByPostMessage(message);
								setSendPostReminderDialog(false);
								loadDues();
							}}
						/>
					</div>
				) : sendSMSReminderDialog ? (
					<div>
						<SMSReminderTemplateDialog
							isOpen={sendSMSReminderDialog}
							onClose={() => {
								setSendSMSReminderDialog(false);
							}}
							sendManually={manualSending}
							reminderToTenant={reminderTenant}
							onSubmit={(message) => {
								setSendByPostMessage(message);
								handlePrint();
								setSendPostReminderDialog(false);
							}}
						/>
					</div>
				) : (
					""
				)}

				{/* tenant recovery dialog steper  */}
				<DialogComponent
					fullWidth
					maxWidth="lg"
					title={getTranslation(
						"Start Recovery Procedure",
						"Start Recovery Procedure",
						"Start Recovery Procedure"
					)}
					open={startRecoveryDialog}
					onClose={() => {
						setStartRecoveryDialog(false);
					}}
				>
					<RecoveryProcedureStepper
						// tenantList={tenantList}
						tenantDetailLis={rows?.data}
						tenantData={tenantInfo}
						handleSubmit={postRecoveryProcedure}
						isLoading={isLoading}
						phoneCall={true}
					/>
				</DialogComponent>
				<DialogComponent
					open={upGradeDialogOpen}
					onClose={() => setUpGradeDialogOpen(false)}
					title={getTranslation(
						"Upgrade Tenant",
						"Upgrade Tenant",
						"Upgrade Tenant"
					)}
				>
					<Upgrade
						descriptionMessage={getTranslation(
							"To use this premium feature you need to upgrade to Professional account.",
							"To use this premium feature you need to upgrade to Professional account.",
							"To use this premium feature you need to upgrade to Professional account."
						)}
					/>
				</DialogComponent>
			</CardContent>
		</CardComponent>
	);
};
export default Tanents;

const Report = React.forwardRef(({ model }, ref) => {
	return <AllTenantReport data={model} ref={ref} />;
});
