import React, { useEffect, useState } from "react";
import { Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import Sidebar from "./sidebar";
import TopBar from "./topbar";
import Breadcrumbs from "../components/BreadCrumbs";
import useStyles from "../assests/styles/layout/main";
import clsx from "clsx";
import { getCustomerApps, getLanguage } from "../actions";
import authUtils from "../utils/authUtils";

export default function DashboardLayout(props) {
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [loadingTranslation, setLoadingTranslation] = useState(true);
  const isMobileTab = useMediaQuery((theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    if (isMobileTab) {
      setNavOpen(false);
      setIsMobile(true);
    } else {
      setNavOpen(true);
      setIsMobile(false);
    }
  }, [isMobileTab]);

  useEffect(() => {
    if (isMobile) setNavOpen(false);
  }, [window.location.pathname]);

  useEffect(() => {
    getUpdatedTranslation();
    
    getCustomerApps(
      {
        email: authUtils.getUser().email,
        appId: 1,
      },
      (resp) => {
        if (resp?.length === 0) {
        
          // setCustomerAppsLoading(false);
          // actions.setSubmitting(false);
        } else {
          try {
           
            // const org=customerApps.find(x=>x.customerId==values.customerId)
            authUtils.setCustomerOrganizations(resp);
           
            // actions.setSubmitting(false);
          } catch (e) {}
        }
      },
      (error) => {
      
      }
    );
  }, []);
  const getUpdatedTranslation = () => {
    getLanguage(
      (resp) => {
        if (resp?.data) {
          localStorage.setItem("dictionary", JSON.stringify(resp.data));
        }
        // alert(localStorage.getItem("lang"))
        if (localStorage.getItem("lang") === null) {
          localStorage.setItem("lang", "fr");
          window.location.reload();
        } else setLoadingTranslation(false);
      },
      (error) => {}
    );
  };
  return loadingTranslation ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <CircularProgress style={{ margin: "auto" }} />
    </Box>
  ) : (
    <div
      className={classes.root}
      style={{ backgroundColor: "rgb(230,231,245)" }}
    >
      <TopBar
        className=""
        navOpen={navOpen}
        handleDrawerOpen={() => setNavOpen(true)}
        isMobile={isMobile}
        onMobileClose={() => setNavOpen(false)}
      />
      <Sidebar onMobileClose={() => setNavOpen(false)} openMobile={navOpen} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: navOpen,
        })}
      >
        <Box
          mb={2}
          className={classes.breadcrums}
          style={{ backgroundColor: "rgb(0,68,103)" }}
        >
          <Breadcrumbs />
        </Box>
        {props.children}
      </main>
    </div>
  );
}
